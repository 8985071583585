var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings"},[_c('ValidationObserver',{ref:"form"},[_c('form',{staticClass:"form",attrs:{"id":"kt_form","novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-card',[_c('b-tabs',_vm._l((_vm.form),function(value,propertyName,index){return _c('b-tab',{key:index,attrs:{"no-body":"","title":_vm.$t(("settings_t." + propertyName))}},[_c('div',{staticClass:"row justify-content-center"},[_c('b-col',{attrs:{"cols":"12"}},_vm._l((value),function(item,name,i){return _c('div',{key:i},[(item.data_type==='text' || item.data_type==='float' || item.data_type==='image' || item.data_type==='text' || item.data_type==='date')?_c('ValidationProvider',{attrs:{"vid":item.key,"name":item.key,"rules":item.is_required ?'required': ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.filterText(name)}},[((item.data_type==='text' || item.data_type==='float'))?_c('b-form-input',{staticClass:"form-control form-control-solid",attrs:{"type":item.data_type==='float'?'number':'text',"placeholder":_vm.filterText(item.key)},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}):_vm._e(),(item.data_type==='image')?_c('ImageUpload',{attrs:{"label":''},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),(item.data_type==='textarea')?_c('ValidationProvider',{attrs:{"vid":item.key,"name":item.key,"rules":item.is_required ?'required': ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.filterText(name)}},[(item.data_type==='textarea')?_c('b-form-textarea',{staticClass:"form-control form-control-solid",attrs:{"type":item.data_type==='float'?'number':'text',"placeholder":_vm.filterText(item.key),"rows":"3"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),(item.data_type==='json' || item.data_type === 'translatable')?_c('div',[(item.key !== 'thankyou_mail_message')?_c('LocaleTabs',{attrs:{"name":"title"},scopedSlots:_vm._u([_vm._l((_vm.languages),function(i,k,idx){return {key:("lang" + idx),fn:function(slotScope){return [_c('ValidationProvider',{key:idx,class:slotScope.selectedTab!==idx?'hidden':'',attrs:{"vid":item.key[k],"name":("" + i),"rules":item.is_required ? 'required':''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":("" + (_vm.filterText(item.key)))}},[_c('b-form-input',{attrs:{"placeholder":("" + i)},model:{value:(item.origin_value[k]),callback:function ($$v) {_vm.$set(item.origin_value, k, $$v)},expression:"item.origin_value[k]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})]}}})],null,true)}):_vm._e()],1):_vm._e(),(item.data_type === 'array')?_c('div',[_c('p',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.filterText(item.key))+" ")]),_vm._l((item.value),function(the_item,the_index){return _c('b-row',{key:the_index},[_c('div',{staticClass:"col-12"},[_c('LocaleTabs',{attrs:{"name":the_item.title},scopedSlots:_vm._u([_vm._l((the_item.title),function(lang_item,lang_k,lang_idx){return {key:("lang" + lang_idx),fn:function(slotScope){return [_c('ValidationProvider',{key:lang_idx,class:slotScope.selectedTab!==lang_idx?'hidden':'',attrs:{"vid":("" + lang_k),"name":"Title","rules":the_item.is_required ? 'required':''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Title"}},[_c('b-form-input',{attrs:{"placeholder":("" + lang_item)},model:{value:(the_item.title[lang_k]),callback:function ($$v) {_vm.$set(the_item.title, lang_k, $$v)},expression:"the_item.title[lang_k]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})]}}})],null,true)})],1),_c('div',{staticClass:"col-12"},[_c('LocaleTabs',{attrs:{"name":the_item.description},scopedSlots:_vm._u([_vm._l((the_item.description),function(lang_item,lang_k,lang_idx){return {key:("lang" + lang_idx),fn:function(slotScope){return [_c('ValidationProvider',{key:lang_idx,class:slotScope.selectedTab!==lang_idx?'hidden':'',attrs:{"vid":("" + lang_k),"name":"Description","rules":the_item.is_required ? 'required':''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Description"}},[_c('b-form-textarea',{attrs:{"placeholder":("" + lang_item)},model:{value:(the_item.description[lang_k]),callback:function ($$v) {_vm.$set(the_item.description, lang_k, $$v)},expression:"the_item.description[lang_k]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})]}}})],null,true)})],1),_c('div',{staticClass:"col-12"},[_c('ImageUpload',{attrs:{"label":''},model:{value:(the_item.icon),callback:function ($$v) {_vm.$set(the_item, "icon", $$v)},expression:"the_item.icon"}}),_c('hr',{staticClass:"my-4"})],1)])})],2):_vm._e(),(item.data_type==='boolean')?_c('b-form-group',{attrs:{"label":_vm.filterText(item.key)}},[_c('span',{staticClass:"switch switch-icon"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.value),expression:"item.value"}],attrs:{"type":"checkbox","true-value":"true","false-value":"false"},domProps:{"checked":Array.isArray(item.value)?_vm._i(item.value,null)>-1:_vm._q(item.value,"true")},on:{"change":function($event){var $$a=item.value,$$el=$event.target,$$c=$$el.checked?("true"):("false");if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "value", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "value", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "value", $$c)}}}}),_c('span')])])]):_vm._e()],1)}),0)],1)])}),1),_c('b-row',[_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('LoadingButton',{attrs:{"is-disabled":_vm.buttonDisabledStatus}})],1)],1)],1)],1),_vm._v(" . ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }